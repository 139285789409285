/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Календарь', route: { name: 'apps-events' }, icon: 'CalendarIcon', isBookmarked: true },
    ],
  },
  files: {
    key: 'file_name',
    data: [],
  },
  contacts: {
    key: 'name',
    data: [],
  },
}
/* eslint-enable */
