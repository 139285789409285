import {
  reactive, getCurrentInstance, watch, toRefs,
} from '@vue/composition-api'
import router from '@/router'
// eslint-disable-next-line object-curly-newline

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export const setUrlParam = () => {
  let out = ''

  return param => {
    if (out) {
      out = `${out}&${param}`
    } else {
      out = param
    }

    return out
  }
}

/* eslint-disable */
export const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const setCookie = (name, value, options = {}) => {

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey
    let optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue
    }
  }

  document.cookie = updatedCookie
}

export const convertMiliseconds = (miliseconds, format) => {
  var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds
  
  total_seconds = parseInt(Math.floor(miliseconds / 1000))
  total_minutes = parseInt(Math.floor(total_seconds / 60))
  total_hours = parseInt(Math.floor(total_minutes / 60))
  days = parseInt(Math.floor(total_hours / 24))

  seconds = parseInt(total_seconds % 60)
  minutes = parseInt(total_minutes % 60)
  hours = parseInt(total_hours % 24)
  
  switch(format) {
	case 's':
		return total_seconds
	case 'm':
		return total_minutes
	case 'h':
		return total_hours
	case 'd':
		return days
	default:
		return { d: days, h: hours, m: minutes, s: seconds }
  }
}

export const declOfNum = (number, titles) => {  
  const cases = [2, 0, 1, 1, 1, 2];  
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
}
/* eslint-enable */
